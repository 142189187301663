// extracted by mini-css-extract-plugin
export var Bitmap = "nereden-satin-alabilirim-module--Bitmap--ghecN";
export var arrow = "nereden-satin-alabilirim-module--arrow--jxjTh";
export var breadcrumb = "nereden-satin-alabilirim-module--breadcrumb--FzIJO";
export var card = "nereden-satin-alabilirim-module--card--+ZuN+";
export var cardHolder = "nereden-satin-alabilirim-module--cardHolder--S0U4D";
export var chevron_down_black_24dp = "nereden-satin-alabilirim-module--chevron_down_black_24dp--fQ8MB";
export var content = "nereden-satin-alabilirim-module--content---ETTm";
export var content_text = "nereden-satin-alabilirim-module--content_text--CqLrq";
export var hb = "nereden-satin-alabilirim-module--hb--7MKDP";
export var headHolder = "nereden-satin-alabilirim-module--headHolder--LfQX1";
export var holder = "nereden-satin-alabilirim-module--holder--Mps5Y";
export var linkHolder = "nereden-satin-alabilirim-module--linkHolder--4SP7h";
export var logoHolder = "nereden-satin-alabilirim-module--logoHolder--Scwjz";
export var mapHolder = "nereden-satin-alabilirim-module--mapHolder--sGVEy";
export var mapcard = "nereden-satin-alabilirim-module--mapcard--QDl3I";
export var maptext = "nereden-satin-alabilirim-module--maptext--gqFCF";
export var product = "nereden-satin-alabilirim-module--product--AoaWw";
export var sprey = "nereden-satin-alabilirim-module--sprey--iqZI0";
export var surup = "nereden-satin-alabilirim-module--surup--GI6iF";
export var textHolder = "nereden-satin-alabilirim-module--textHolder--AE39A";
export var ty = "nereden-satin-alabilirim-module--ty--Ad8ry";