import * as React from "react";
import { SyntheticEvent, useEffect, useState } from "react";
import { PageProps } from "gatsby";
import Layout from "../components/layout/layout";
import { Breadcrumb } from "../components/breadcrumb/breadcrumb";
import Container from "../components/container/container";
import * as styles from "./nereden-satin-alabilirim.module.scss";
import classNames from "classnames";
import { Level } from "react-accessible-headings";
import Heading from "../components/basic-components/heading/heading";
import GoogleMap from "../components/map";
import Arrow from "../images/svgs/keyboard_arrow_down_black_24dp.svg";
import Location from "../images/svgs/location.svg";
import { Select } from "react-responsive-select";
import { IOutputSingleSelect } from "react-responsive-select/dist/types";
import { Helmet } from "react-helmet";
import logo from "../images/Bisolnatur-logo.svg";
import bisolduoSurupLogo from "../images/bisolduo-surup-logo.svg";
import bisolduoSpreyLogo from "../images/bisolduo-sprey-logo.svg";
import BisolviralSpreyLogo from "../images/bisolviral-logo.svg";
import arrow from "../images/chevron_down_black_24dp@3x.png";
import bisolnaturProduct from "../images/bisolnatur-urun-yeni.png";
import bisolviralProduct from "../images/bisolviralkutu.png";
import bisolduoSpreyProduct from "../images/product-bar-bisolduo-sprey.png";
import bisolduoSurupProduct from "../images/product-bar-bisolduo-surup.png";
import mapsIcon from "../images/google-maps.png";
import chevronRight from "../images/chevron_right_black_24dp.svg";

type City = {
  _id?: string;
  name?: string;
  geolocation?: {
    lat: string;
    lon: string;
  };
};
let selectedCity: City | undefined;
let selectedCounty: City | undefined;
const askApi = async (uri: string) => {
  const response = await fetch(uri);
  const jsonResponse = await response.json();
  const newData: {
    text?: string;
    value?: string;
    location?: { lat?: number; lng?: number };
  }[] = [];
  if (jsonResponse.status === true && jsonResponse.data?.length > 0) {
    jsonResponse?.data.forEach((value: City) => {
      newData.push({
        text: value.name,
        value: value._id,
        location: {
          lat: parseFloat(value.geolocation?.lat || ""),
          lng: parseFloat(value.geolocation?.lon || ""),
        },
      });
    });
  }
  return newData;
};

const WhereToBuyPage: React.FC<PageProps> = ({ location }: PageProps) => {
  const [cities, setCities] = useState<{ text?: string; value?: string }[]>([
    { text: "İl Seçiniz", value: "" },
  ]);
  const [counties, setCounties] = useState<{ text?: string; value?: string }[]>(
    [{ text: "İlçe Seçiniz", value: "" }]
  );
  const [neighborhoods, setNeighborhoods] = useState<
    { text?: string; value?: string }[]
  >([
    {
      text: "Mahalle Seçiniz",
      value: "",
    },
  ]);
  const [myLocation, setMyLocation] = useState<
    { lat?: number; lng?: number } | string
  >({
    lat: 39.925533,
    lng: 32.866287,
  });
  const [zoom, setZoom] = useState(6);
  const [type, setType] = useState("city");

  useEffect(() => {
    askApi(
      "https://www.bisolnatur.com.tr/v1/cities?fields=name,geolocation.lat,geolocation.lon"
    ).then((data) => setCities([{ text: "İl Seçiniz", value: "" }, ...data]));
  }, []);

  const onCityChange = ({ value }: IOutputSingleSelect) => {
    if (value === "") {
      setCounties([{ text: "İlçe Seçiniz", value: "" }]);
      setNeighborhoods([{ text: "Mahalle Seçiniz", value: "" }]);
    }
    selectedCity = cities.find((city) => city.value === value);
    setMyLocation(selectedCity.location);
    setZoom(9);
    setType("city");
    askApi(
      "https://www.bisolnatur.com.tr/v1/cities/" +
      value +
      "/towns?fields=name,geolocation.lat,geolocation.lon"
    ).then((data) => {
      setCounties([{ text: "İlçe Seçiniz", value: "" }, ...data]);
      setNeighborhoods([{ text: "Mahalle Seçiniz", value: "" }]);
    });
  };

  const onCountyChange = ({ value }: IOutputSingleSelect) => {
    if (value === "") {
      setNeighborhoods([{ text: "Mahalle Seçiniz", value: "" }]);
    }
    selectedCounty = counties.find((county) => county.value === value);
    setMyLocation(selectedCounty.location);
    setZoom(12);
    setType("county");
    askApi(
      "https://www.bisolnatur.com.tr/v1/towns/" +
      value +
      "/neighborhoods?fields=name,geolocation.lat,geolocation.lon"
    ).then((data) =>
      setNeighborhoods([{ text: "Mahalle Seçiniz", value: "" }, ...data])
    );
  };

  const onNeighborhoodChange = ({ value, text }: IOutputSingleSelect) => {
    setMyLocation(selectedCity.text + " " + selectedCounty.text + " " + text);
    setZoom(17);
    setType("neighborhood");
  };

  const findMyLocation = (e: SyntheticEvent) => {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setMyLocation({
          lng: position.coords.longitude,
          lat: position.coords.latitude,
        });
        setZoom(17);
        setType("neighborhood");
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  return (
    <Layout
      hideWhereToBuy={true}
      seoConfig={{
        title: "Bisolnatur ve Bisolduo'yu Nereden Satın Alabilirim?",
        description:
          "Bisolnatur ve Bisolduo'yu online pazar yerlerinden, eczanelerden reçetesiz satın alabilirsiniz, çocuklarda doktora danışılarak kullanılması gerekmektedir.",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Bisolnatur ve Bisolduo'yu Nereden Satın Alabilirim?","item": "https://www.bisolnatur.com.tr/nereden-satin-alabilirim"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "headline": "Tüm öksürük tipleri için etkili ve doğal şurup Bisolnatur, kuru ve balgamlı öksürüğü rahatlatır.",
            "name" : "Öksürük Şurubu Satın Al",
            "description": "Tamamen doğal içeriklerden oluşan ve güvenle kullanabileceğiniz öksürük şurubu Bisolnatur'u tüm eczanelerden reçeteye ihtiyaç duymadan satın alabilirsiniz.",
            "url": "https://www.bisolnatur.com.tr/nereden-satin-alabilirim",
            "image": [
            "https://www.bisolnatur.com.tr/bisolnatur-urun-yeni.png"
            ]
          }
          `}
        </script>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
            "@type": "Question",
            "name": "Bisolnatur ne kadar süre ile kullanılmalı?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Belirtileriniz bir hafta içerisinde iyileşmezse veya kötüleşirse doktorunuza danışınız. Doktorunuz ürünü kullanmaya devam edip etmemeniz konusunda size öneride bulunabilir. Daima kullanma kılavuzunu inceleyiniz ve ürünü ne kadar süre ile kullanacağınız konusunda doktorunuza ya da eczacınıza danışınız."
          }
          }, {
            "@type": "Question",
            "name": "Gebelik ve emzirme sırasında Bisolnatur kullanabilir miyim?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Bisolnatur'u gebelik ve emzirme sırasında doktorunuza danışarak kullanabilirsiniz."
          }
          }, {
            "@type": "Question",
            "name": "Bisolnatur glüten içeriyor mu?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Hayır. Bisolnatur glüten içermez. Eğer emin değilseniz tedavi edici herhangi bir ürünü kullanmadan önce daima etiketi okuyunuz ve doktorunuza ya da eczacınıza danışınız."
          }
          }, {
            "@type": "Question",
            "name": "Bisolnatur çocuklar tarafından kullanılabilir mi?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Bisolnatur doğal ve bitkisel bir üründür ve 1 yaşından büyük çocukların kullanımı için güvenlidir. Tüm tedavilerde olduğu gibi, kutuda bulunan (online olarak da bulunmaktadır) kullanım kılavuzundaki doz uygulama talimatlarına uyunuz ve doktorunuza danışınız."
          }
          }]
          }
          `}
        </script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth className={styles.holder}>
        <Container>
          <div className={styles.headHolder}>
            <div className={styles.content_text}>
              <div>
                <Heading>Nereden satın alabilirim?</Heading>
                <p>
                  Bisolnatur ve Bisolduo’yu eczanelerden ve online satış noktalarından reçetesiz satın alabilirsiniz, çocuklarda doktora danışılarak kullanılması gerekmektedir.
                </p>
              </div>
              <a className={styles.mapcard} href="https://www.google.com/maps/search/?api=1&query=pharmacy" target="_blank">
                <img className={styles.Bitmap} src={mapsIcon} />
                <span className={styles.maptext}>
                  Size en yakın eczaneyi bulun
                </span>
                <img src={chevronRight}
                  className={styles.chevron_down_black_24dp}></img>
              </a>
            </div>
            <Level>
              <Heading className="green">Online satın alın</Heading>
            </Level>
            <div className={styles.cardHolder}>
              <div className={styles.card}>
                <div className={classNames(styles.logoHolder, "flexbox")}>
                  <img
                    className={classNames(styles.product)}
                    src={bisolnaturProduct}
                    alt="Ürün görseli"
                    width="100"
                    height="100"
                  />
                  <img
                    src={logo}
                    alt="Bisolnatur logo"
                    width="187"
                    height="35"
                  />
                </div>
                <a
                  href="https://www.trendyol.com/bisolnatur/dogal-icerikli-oksuruk-surubu-p-242142048?merchantId=356621&filterOverPriceListings=false&utm_source=bisolnatur&utm_medium=ecommercepage&utm_campaign=ecommerce&utm_term=trendyol"
                  target="_blank"
                  className={classNames(styles.linkHolder, "flexbox", "orange")}
                  rel="noreferrer nofollow"
                >
                  <span>Satın Al</span>
                  <img
                    className={styles.arrow}
                    src={arrow}
                    alt="ok"
                    width="24"
                    height="24"
                  />
                </a>
              </div>
              <div className={styles.card}>
                <div className={classNames(styles.logoHolder, "flexbox")}>
                  <img
                    className={classNames(styles.product)}
                    src={bisolduoSpreyProduct}
                    alt="Ürün görseli"
                    width="100"
                    height="100"
                  />
                  <img
                    className={classNames(styles.sprey)}
                    src={bisolduoSpreyLogo}
                    alt="Bisolduo Sprey logo"
                    width="130"
                    height="46"
                  />
                </div>
                <a
                  href="https://www.trendyol.com/sanofi/bisolduo-sprey-20-ml-p-148795071?merchantId=356621&filterOverPriceListings=false&utm_source=bisolduosprey&utm_medium=ecommercepage&utm_campaign=ecommerce&utm_term=trendyol"
                  target="_blank"
                  className={classNames(styles.linkHolder, "flexbox", "orange")}
                  rel="noreferrer nofollow"
                >
                  <span>Satın Al</span>
                  <img
                    className={styles.arrow}
                    src={arrow}
                    alt="ok"
                    width="24"
                    height="24"
                  />
                </a>
              </div>
              <div className={styles.card}>
                <div className={classNames(styles.logoHolder, "flexbox")}>
                  <img
                    className={classNames(styles.product)}
                    src={bisolduoSurupProduct}
                    alt="Ürün görseli"
                    width="100"
                    height="100"
                  />
                  <img
                    className={classNames(styles.surup)}
                    src={bisolduoSurupLogo}
                    alt="Bisolduo Şurup logo"
                    width="130"
                    height="48"
                  />
                </div>
                <a
                  href="https://www.trendyol.com/sanofi/bisolduo-bal-ve-hatmi-koku-kuru-oksuruk-suruby-100-ml-p-148074825?merchantId=356621&filterOverPriceListings=false&utm_source=bisolduosyrup&utm_medium=ecommercepage&utm_campaign=ecommerce&utm_term=trendyol"
                  target="_blank"
                  className={classNames(styles.linkHolder, "flexbox", "orange")}
                  rel="noreferrer nofollow"
                >
                  <span>Satın Al</span>
                  <img
                    className={styles.arrow}
                    src={arrow}
                    alt="ok"
                    width="24"
                    height="24"
                  />
                </a>
              </div>
              <div className={styles.card}>
                <div className={classNames(styles.logoHolder, "flexbox")}>
                  <img
                      className={classNames(styles.product)}
                      src={bisolviralProduct}
                      alt="Ürün görseli"
                      width="100"
                      height="100"
                  />
                  <img
                      className={classNames(styles.sprey)}
                      src={BisolviralSpreyLogo}
                      alt="BisolViral Sprey logo"
                      width="130"
                      height="46"
                  />
                </div>
                <a
                    href="https://www.trendyol.com/bisolnatur/bisolviral-agiz-spreyi-20-ml-p-364839199"
                    target="_blank"
                    className={classNames(styles.linkHolder, "flexbox", "orange")}
                    rel="noreferrer nofollow"
                >
                  <span>Satın Al</span>
                  <img
                      className={styles.arrow}
                      src={arrow}
                      alt="ok"
                      width="24"
                      height="24"
                  />
                </a>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </Layout>
  );
};

export default WhereToBuyPage;
